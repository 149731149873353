(function() {
  var attachAutocompletionHandler, delay;

  delay = function(callback, ms) {
    var timer;
    timer = 0;
    return function() {
      var args, context;
      context = this;
      args = arguments;
      clearTimeout(timer);
      timer = setTimeout((function() {
        callback.apply(context, args);
      }), ms || 0);
    };
  };

  attachAutocompletionHandler = function() {
    return $('#area_finder_query').keyup(delay((function(e) {
      var query, url;
      query = $(this).val();
      url = $('#new_area_finder').attr('action');
      $.ajax({
        url: url,
        contentType: 'application/x-www-form-urlencoded',
        data: {
          area_finder: {
            query: query
          }
        },
        dataType: 'script'
      });
    }), 500));
  };

  $(document).on("turbolinks:load", attachAutocompletionHandler);

}).call(this);
